import { combineReducers } from 'redux';
import { FETCH_TAGS, FETCH_IMAGES, REQUEST, INITIAL_IMAGES, FETCH_IMAGE_HEADER } from '../actions';

const stateImage = (state = [], action) => {
	switch (action.type) {
        case REQUEST:
            return { 
                fetchImages: true,
                fetchTags: true,
                ...state
            }
        case FETCH_IMAGES:
            const { images = [] } = state;
            const ImageDict = action.payload.images.items.map( 
                ( item ) =>  { return {...item, path: `/img/${item.name}`} }
            )
            return {
                ...state, images: [...images, ...ImageDict], 
                fetchImages: false, countImages: action.payload.images.count
            }
        case INITIAL_IMAGES:
            return {
                ...state, images: [], fetchImages: true
            }
        case FETCH_TAGS: 
            const { tags } = action.payload
            return {
                ...state, tags, fetchTags: false
            }
        case FETCH_IMAGE_HEADER: 
            const { headerImage } = action.payload;
            console.log(headerImage);

            return {
                ...state, headerImage: { path: `/img/${headerImage.name }`}
            }

        default:
            return state
    }
}

const reducers = combineReducers ({
    stateImage
});

export default reducers;
