import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import thunk from "redux-thunk";
import allReducers from './reducers';
import logger from 'redux-logger'
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import {
    BrowserRouter,
    Route,
} from 'react-router-dom'
  
const store = createStore(
    allReducers, 
    compose(applyMiddleware(thunk, logger)),
);


ReactDOM.render(
    <BrowserRouter>
        <Provider store={ store }>
            <Route path='/' component={App} />
        </Provider>
    </BrowserRouter>, document.getElementById('root')
);

serviceWorker.unregister();
