import React from 'react';
import StackGrid from "react-stack-grid";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetch_images, fetch_tags, initial_fetch_images, fetch_headerImage } from './actions/index'
import Spinner from './Spinner';
import { Link } from 'react-router-dom'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      picked: [],
    };
  }

  componentDidMount(){
    this.props.fetch_headerImage();
    const queryParams = new URLSearchParams(this.props.location.search);
    const id = queryParams.get('id');
    if (id !== null){
      this.props.initial_fetch_images(new Array(id));
      this.setState({picked: new Array(id)});
    }else{
      this.props.initial_fetch_images();
    }

    this.props.fetch_tags();
    document.addEventListener('scroll', this.trackScrolling);
  }

  isBottom = () => {
    const wrappedElement = document.getElementById('root');
    const rect = wrappedElement.getBoundingClientRect()
    const { bottom } = rect
    return bottom <= window.innerHeight + 200;
  }

  getScrollElement = () => document.getElementById('root')

  componentDidUpdate = (prevProps) => {
    if (prevProps.location.search !== this.props.location.search){
      const queryParams = new URLSearchParams(this.props.location.search);
      const id = queryParams.get('id')
      if (id !== null){
        this.props.initial_fetch_images(new Array(id));
        this.setState({picked: new Array(id)})
      }
    }
    if (this.props.countImages > this.props.images.length){
      document.addEventListener('scroll', this.trackScrolling);
    }
  }


  trackScrolling = () => {
    if (this.isBottom) {
      if (this.props.countImages > this.props.images.length){
        this.props.fetch_images(this.state.picked, this.props.images.length)
      }
      document.removeEventListener('scroll', this.trackScrolling);
    }
  };

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  render(){
    return (
      <div>
        <div>
          <Link to={'/'}>
            <div className='header-image' style={{  backgroundImage: `url(${this.props.headerImage.path})`, cursor:'pointer'}} onClick={() => {
              this.props.initial_fetch_images()
              this.setState({ picked: [] })}}
            />
          </Link>
          {
            this.props.fetchTags
              ?
            <Spinner/>
              :
            <p className='hash-tag-text'> 
              {this.props.tags.map((e, index) => (
                <Link key={index} to={`/?id=${e._id}`} style={this.state.picked.includes(e._id) ? {color: '#d46bbd'} : null}>#{e.name} </Link>
              ))}
            </p>
          }
          { 
              this.props.fetchImages 
            ?
            <Spinner/>
            :
              <StackGrid
                monitorImagesLoaded={true}
                columnWidth={236}
                gutterWidth={20}
                gutterHeight={20}
                duration={10}   
                style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1300px' }}
              >
                {
                  this.props.images.map((obj, index) => 
                    <img key={index + 'image'}
                      src={obj.path} alt={obj.name}
                      className='image-items'/>)
                }
              </StackGrid>
            }
        </div>
      </div>
    );
  }
}

function matchDispatchToProps(dispatch) {
	return bindActionCreators(
    { 
      fetch_images, fetch_tags, initial_fetch_images, fetch_headerImage
    }, 
    dispatch)
}

function mapStateToProps(state) {
    const { images = [], fetchImages, fetchTags, tags = [], countImages, headerImage = { name: ''} } = state.stateImage;
    return { images, fetchImages, tags, fetchTags, countImages, headerImage };
}

export default connect(mapStateToProps, matchDispatchToProps)(App);
