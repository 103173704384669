import axios from 'axios';

export const REQUEST = 'REQUEST';
export const FETCH_IMAGES = 'FETCH_IMAGES';
export const FETCH_TAGS = 'FETCH_TAGS';
export const INITIAL_IMAGES = 'INITIAL_IMAGES'
export const FETCH_IMAGE_HEADER = 'FETCH_IMAGE_HEADER';

const core_url = '/api/graph?'

export const fetch_images = ( idsTags=[], offsets = 0, first = 35 ) => async dispatch => {
    const offset =`pagination: { offset: ${JSON.stringify(offsets)}, first: ${JSON.stringify(first)}}`
    const filter = idsTags.length > 0  ? `idsTags: ${JSON.stringify(idsTags)}` : ""

    dispatch({ type: REQUEST })
    const query = `{
        images (${filter} ${offset}){
            count
            items{
                name
                height
                _id
            }
        }
    }`
    const payload  = await axios.post(core_url, {query})
        .then(response => response.data.data)
        .catch(error => console.log(error))

    dispatch({ type: FETCH_IMAGES, payload });
}

export const initial_fetch_images = ( idsTags=[], offsets = 0, first = 35 ) => async dispatch => {
    dispatch({ type: INITIAL_IMAGES });
    dispatch(fetch_images(idsTags, offsets, first));
}

export const fetch_tags = () => async dispatch => {
    dispatch({ type: REQUEST });
    const query = `{
        tags {
          name
          _id
        }  
    }`
    const payload  = await axios.post(core_url, {query})
        .then(response => response.data.data)
        .catch(error => console.log(error))

    dispatch({ type: FETCH_TAGS, payload });
}

export const fetch_headerImage = () => async dispatch => {
    const query = `{
        headerImage {
          name
        }
    }`
    const payload  = await axios.post(core_url, {query})
    .then(response => response.data.data)
    .catch(error => console.log(error));

    dispatch({ type: FETCH_IMAGE_HEADER, payload });
}